import Countdown from 'react-countdown'

export const NavLinks = ({ setMintModalOpen }) => (
  <div className="links">
    <a href="/">
      Home
    </a>
    <a href="/gallery" className="linkHover soon">
      Gallery
    </a>
    <button className="mintGlyph" onClick={() => setMintModalOpen(true)}>
      <h3>MINT</h3>
    </button>
  </div>
)

export const AuctionStart = (
  <>
    <div className="scrollContainer">
      <p className="scrollText">
        NFT DROPS
      </p>
    </div>
    <div className="scrollContainer">
      <p className="altText auctionAlt">
        May 2022
        {/* May 1st, 2022 12PM PST */}
        {/* <Countdown date={new Date('April 1, 2022 20:00:00 GMT+00:00').getTime()} /> */}
      </p>
    </div>
  </>
)

export const AllowListStart = (
  <div className="scrollContainer">
    <p className="">
      ALLOW LIST MINT STARTS
    </p>
    <p className="altText time">
      <Countdown date={new Date('April 1, 2022 20:00:00 GMT+00:00').getTime()} />
    </p>
  </div>
)

export const PublicSaleStart = (
  <div className="scrollContainer">
    <p className="">
      PUBLIC MINT STARTS
    </p>
    <p className="altText time">
      <Countdown date={new Date('April 1, 2022 20:00:00 GMT+00:00').getTime()} />
    </p>
  </div>
)